'use client';

import * as React from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';

export interface IBlockProps {
  children: React.ReactNode;
  theme?: 'default' | 'lightest' | 'lighter' | 'light' | 'medium' | 'dark' | 'category01' | 'category02' | 'category03' | 'category04';
  width?: 'default' | 'narrow' | 'medium' | 'article' | 'max' | 'full' | 'search';
  compact?: boolean;
  className?: string;
}

const Block: React.FunctionComponent<IBlockProps> = (props) => {
  return (
    <div
      className={classNames(
        styles['block'],
        {
          [styles['theme-default']]: props.theme === 'default' || !props.theme,
          [styles['theme-lightest']]: props.theme === 'lightest',
          [styles['theme-lighter']]: props.theme === 'lighter',
          [styles['theme-light']]: props.theme === 'light',
          [styles['theme-medium']]: props.theme === 'medium',
          [styles['theme-dark']]: props.theme === 'dark',
          [styles['theme-category01']]: props.theme === 'category01',
          [styles['theme-category02']]: props.theme === 'category02',
          [styles['theme-category03']]: props.theme === 'category03',
          [styles['theme-category04']]: props.theme === 'category04',

          [styles['width-default']]: props.width === 'default' || !props.width,
          [styles['width-narrow']]: props.width === 'narrow',
          [styles['width-medium']]: props.width === 'medium',
          [styles['width-article']]: props.width === 'article',
          [styles['width-max']]: props.width === 'max',
          [styles['width-full']]: props.width === 'full',
          [styles['width-search']]: props.width === 'search',

          [styles['compact']]: props.compact,
        },
        props.className,
      )}
    >
      {props.children}
    </div>
  );
};

Block.displayName = 'Block';

export default Block;
