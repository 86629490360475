'use client';

import * as React from 'react';
import classNames from 'classnames';
import Link from 'next/link';
import LoadingContent from '@/components/ui/LoadingContent';
import Icon, { IconsAvailable } from '../Icon';
import styles from './styles.module.scss';

export interface IButtonProps {
  /** title to use */
  title: React.ReactNode;
  /** size to use */
  size?: 'default' | 'small' | 'medium' | 'large';
  /** click event, should not be used with type 'anchor' */
  onClick?: (e: React.SyntheticEvent) => void;
  /** intent variation */
  theme?:
    | 'default'
    | 'primary'
    | 'secondary'
    | 'transparent'
    | 'white-default'
    | 'white-primary'
    | 'white-secondary'
    | 'white-transparent'
    | 'danger'
    | 'link';
  /** disable clicking */
  disabled?: boolean;
  /** disable clicking and display loader */
  loading?: boolean;
  /** DOM target element name attribute */
  name?: string;
  /** DOM target element id attribute */
  id?: string;
  /** Icon to display */
  iconLeft?: IconsAvailable;
  /** Icon to display */
  iconRight?: IconsAvailable;
  /** className to append */
  className?: string;
  /** Type param to pass */
  type?: 'button' | 'submit' | 'reset';
  /** Menu */
  menu?: {
    label: string;
    icon?: IconsAvailable;
    onClick: (e: React.SyntheticEvent) => void;
  }[];
  /** Use link instead of button */
  href?: string;
  /** link target */
  target?: string;
}

/**
 * Displays button
 */
const Button: React.FunctionComponent<IButtonProps> = (props) => {
  const [dropOpen, setDropOpen] = React.useState(false);
  const moreButtonRef = React.useRef<HTMLButtonElement>(null);
  const moreLinkRef = React.useRef<HTMLAnchorElement>(null);
  const dropRef = React.useRef<HTMLUListElement>(null);

  const buttonClass = classNames(
    styles['button'],
    {
      [styles['disabled']]: props.disabled,
      [styles['loading']]: props.loading,

      [styles['size-default']]: !props.size || props.size === 'default',
      [styles['size-small']]: props.size === 'small',
      [styles['size-large']]: props.size === 'large',
      [styles['size-medium']]: props.size === 'medium',

      [styles['theme-default']]: props.theme === 'default' || !props.theme,
      [styles['theme-primary']]: props.theme === 'primary',
      [styles['theme-secondary']]: props.theme === 'secondary',
      [styles['theme-transparent']]: props.theme === 'transparent',
      [styles['theme-white']]: props.theme === 'white-default',
      [styles['theme-white-primary']]: props.theme === 'white-primary',
      [styles['theme-white-secondary']]: props.theme === 'white-secondary',
      [styles['theme-white-transparent']]: props.theme === 'white-transparent',
      [styles['theme-link']]: props.theme === 'link',
      [styles['theme-danger']]: props.theme === 'danger',
    },
    props.className,
  );

  React.useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target;
      if (dropOpen) {
        if (target && dropRef.current && dropRef.current.contains(target as Node)) {
          setDropOpen(false);
          return;
        }
        if (
          target &&
          ((moreButtonRef.current && !moreButtonRef.current.contains(target as Node)) ||
            (moreLinkRef.current && !moreLinkRef.current.contains(target as Node)))
        ) {
          setDropOpen(false);
        }
      }
    };
    globalThis.addEventListener('click', handleClickOutside);
    return () => {
      globalThis.removeEventListener('click', handleClickOutside);
    };
  }, [dropOpen, moreButtonRef, dropRef]);

  const renderContents = (
    <span className={styles['button__inner']}>
      {props.iconLeft && (
        <Icon
          className={classNames(styles['button__icon'], styles['button__icon-left'])}
          width={20}
          height={20}
          kind={props.iconLeft}
        />
      )}
      <span className={styles['button__text']}>{props.title}</span>
      {props.iconRight && (
        <Icon
          className={classNames(styles['button__icon'], styles['button__icon-right'])}
          width={20}
          height={20}
          kind={props.iconRight}
        />
      )}
      {props.loading && (
        <LoadingContent
          size="tiny"
          layout="absolute"
        />
      )}
    </span>
  );

  return (
    <span
      className={buttonClass}
      draggable={false}
    >
      {props.href ? (
        <Link
          href={props.href}
          className={classNames(styles['button__element'])}
          onClick={(e) => {
            if (typeof props.onClick !== 'undefined') {
              props.onClick(e);
            }
            if (typeof props.menu !== 'undefined' && props.menu.length > 0) {
              setDropOpen(!dropOpen);
            }
          }}
          id={props.id}
          aria-busy={props.loading}
          target={props.target}
          ref={moreLinkRef}
        >
          {renderContents}
        </Link>
      ) : (
        <button
          className={classNames(styles['button__element'])}
          type={props.type || 'button'}
          onClick={(e) => {
            if (typeof props.onClick !== 'undefined') {
              props.onClick(e);
            }
            if (typeof props.menu !== 'undefined' && props.menu.length > 0) {
              setDropOpen(!dropOpen);
            }
          }}
          disabled={props.disabled}
          name={props.name}
          id={props.id}
          aria-busy={props.loading}
          ref={moreButtonRef}
        >
          {renderContents}
        </button>
      )}
      {typeof props.menu !== 'undefined' && props.menu.length > 0 && (
        <ul
          ref={dropRef}
          {...(dropOpen ? {} : { inert: '' })} // https://github.com/facebook/react/issues/17157
          className={classNames(styles['button__drop'], { [styles['open']]: dropOpen })}
        >
          {props.menu.map((item) => (
            <li key={item.label}>
              <button
                role="button"
                onClick={item.onClick}
              >
                <div className={styles['button__drop__inner']}>
                  {item.icon && (
                    <Icon
                      width={20}
                      height={20}
                      kind={item.icon}
                    />
                  )}
                  <div className={styles['button__drop__label']}>{item.label}</div>
                </div>
              </button>
            </li>
          ))}
        </ul>
      )}
    </span>
  );
};

Button.displayName = 'Button';

export default Button;
